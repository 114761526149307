import React from "react";
import useFetchCookiesPolicy from "./CookiePolicyPage.hooks";
import { Row, Container, Col } from "react-bootstrap";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";

export const Content = () => {
  const { data } = useFetchCookiesPolicy();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" xl="10">
            <SH.Wrapper.WrapperWysiwyg>
              {ReactHtmlParser(data.strapiCookies.post.content)}
            </SH.Wrapper.WrapperWysiwyg>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
