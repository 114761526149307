import { useStaticQuery, graphql } from "gatsby";

const useFetchCookiesPolicy = () => {
  const data: TData = useStaticQuery(graphql`
    {
      strapiCookies {
        post {
          content
        }
      }
    }
  `);

  return { data };
};

export default useFetchCookiesPolicy;

type TData = {
  strapiCookies: {
    post: {
      content: string;
    };
  };
};
